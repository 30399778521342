import React from 'react'
import { Flex, Image, Text, Stack, Box, Heading } from '@pw/ui'
import brittneyPhoto from '../../images/homepage_brittney.jpg'

const AboutMe = (props) => (
  <Flex
    bg="white"
    w="100%"
    overflow="hidden"
    alignItems="center"
    flexDirection={{ base: 'column', md: 'row' }}
    {...props}
  >
    <Box
      alignSelf="stretch"
      justifyContent="stretch"
      flex="1"
      bg="gray.50"
      w="100%"
      maxW={{ md: '50%' }}
      maxH="600px"
      minH="600px"
    >
      <Image
        src={brittneyPhoto}
        minH="600px"
        objectFit="cover"
        backgroundPosition="center"
      />
    </Box>

    <Box flex="1" bg="white" w="100%" maxW={{ base: '100%', md: '50%' }}>
      <Box w="100%" maxW={{ md: '600px' }}>
        <Box
          borderColor="rgba(255, 255, 255, 0.6)"
          borderWidth="2px"
          position="relative"
          left={{ base: 'inherit', md: '-100px ' }}
          p="2px"
        >
          <Box position="relative" zIndex="2" p="10" bg="white">
            <Heading
              as="h2"
              pt="2"
              pb="4"
              color="gray.600"
              fontWeight="400"
              textTransform="none"
              fontFamily="body"
              fontSize="4xl"
            >
              I'm Brittney Ayers.
            </Heading>

            <Stack spacing="4" color="gray.500" fontSize="lg">
              <Text>
                I’m an American girl with wellness wanderlust — obsessed with finding
                creative ways to make wellness travel accessible.
              </Text>

              <Text>
                I seek out affordable retreats in offbeat destinations throughout the
                world and create my own wellness weekends in health-centric cities across
                the USA, yet I also embrace health-focused “staycations” closer to home.
              </Text>

              <Text>
                Through Passport to Wellness, I hope to help others improve their health
                through travel. Together we’ll chase zen and find wellness between the
                pages of our passports.
              </Text>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  </Flex>
)

export default AboutMe
