import React from 'react'
import { Flex, Box, SectionHeading } from '@pw/ui'

import Section from '../../components/Layout/Section'
import SearchBarBasic from '../../components/Layout/SearchBarBasic'

const Search = (props) => (
  <Section bg="green.100" my="10" {...props}>
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      py={{ base: 0, md: '10' }}
    >
      <SectionHeading second="Search" size="md" />

      <Box py="4" width="100%" maxWidth={{ base: '90%', md: '500px' }}>
        <SearchBarBasic mt={{ base: 0, md: '4' }} inputGroupProps={{ size: 'lg' }} />
      </Box>
    </Flex>
  </Section>
)

export default Search
