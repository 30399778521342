import React from 'react'
import { navigate } from 'gatsby'
import { FiSearch as SearchIcon } from 'react-icons/fi'
import { Box, Icon, Input, InputGroup, InputRightElement } from '@pw/ui'

const SearchBarBasic = ({
  inputGroupProps = {},
  inputProps = {},
  initialValue = '',
  ...props
}) => {
  const [query, setQuery] = React.useState(initialValue)
  const [isValid, setIsValid] = React.useState(true)
  const onChangeSearch = (e) => setQuery(e?.target?.value || '')

  const onSubmitSearch = (e) => {
    e.preventDefault()

    if (!query) {
      setIsValid(false)
    } else {
      setIsValid(true)

      navigate(`/search?query=${query}`)
    }
  }

  return (
    <Box as="form" onSubmit={onSubmitSearch} {...props}>
      <InputGroup size="sm" {...inputGroupProps}>
        <Input
          isFullWidth
          value={query}
          onChange={onChangeSearch}
          rounded="md"
          bg="beige"
          color="brownLightColor"
          borderColor="brownLightColor"
          _focus={{
            borderColor: 'brownLightColorHover',
            color: 'brownLightColorHover',
          }}
          _placeholder={{ color: 'brownLightColorHover' }}
          _hover={{ color: 'brownLightColorHover' }}
          type="search"
          placeholder="Search the site"
          isValid={isValid}
          errorBorderColor="red.300"
          {...inputProps}
        />

        <InputRightElement>
          <Icon
            as={SearchIcon}
            size="20px"
            color="darkerBeige"
            mr="2"
            onClick={onSubmitSearch}
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}

export default SearchBarBasic
