import React from 'react'
import { Flex, Box, SectionHeading } from '@pw/ui'

import { NewsletterForm } from '../../components/Layout'
import Section from '../../components/Layout/Section'

const Newsletter = (props) => (
  <Section bg="green.100" {...props}>
    <Flex alignItems="center" justifyContent="center" flexDirection="column" py="10">
      {/* <Heading as="h5" pt="4" fontWeight="100" fontSize="xl">
            Get the Latest
          </Heading>
          <Heading as="h2" pb="4" fontWeight="400" fontSize="4xl">
            Sign up for my Newsletter
          </Heading> */}

      <SectionHeading
        first="Get the Latest"
        second="Sign up for my newsletter"
        size="md"
      />

      <Box py="4">
        <NewsletterForm />
      </Box>
    </Flex>
  </Section>
)

export default Newsletter
