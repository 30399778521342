import React from 'react'
import { graphql, Link as RouterLink } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'
import { Flex, Heading, Stack, Box, Text } from '@pw/ui'
import { useQueryParam, StringParam } from 'use-query-params'

import { Layout, Container } from '../components/Layout'
import Section from '../components/Layout/Section'
import PostGrid from '../components/Posts/Grid'
import SearchBarBasic from '../components/Layout/SearchBarBasic'

export const SearchPage = ({ data, location }) => {
  const index = data?.localSearchPosts?.index || ''
  const store = data?.localSearchPosts?.store || {}
  const posts = data?.allContentfulBlogPost?.edges || []

  const [query, setQuery] = useQueryParam('query', StringParam)
  const results = useFlexSearch(query, index, JSON.parse(store))

  const searchResults = results.map((post) => {
    const foundPost = posts.find(({ node }) => node.id === post.id)

    if (foundPost?.node?.id || foundPost?.id) {
      return foundPost?.node || foundPost
    }

    return post
  })

  return (
    <Layout location={location}>
      <Section maxWidth="1200px" mx="auto">
        <Flex justifyContent="left">
          {query ? (
            <Heading as="h1" fontSize="4xl" fontWeight="normal" textTransform="none">
              Searching for{' '}
              <Text as="span" fontWeight="bold" fontStyle="italic">
                {query}
              </Text>
            </Heading>
          ) : (
            <Heading
              as="h1"
              fontSize="4xl"
              fontWeight="normal"
              textTransform="capitalize"
            >
              Search
            </Heading>
          )}
        </Flex>

        <SearchBarBasic
          mt="4"
          maxWidth="500px"
          inputGroupProps={{ size: 'lg' }}
          initialValue={query}
        />
      </Section>

      <Container maxWidth="1200px" mx="auto">
        {Boolean(query) && (
          <Stack spacing="2">
            {!results.length && (
              <Box boxShadow="sm" bg="white">
                No Results Were Found. Try a different search query.
              </Box>
            )}

            <PostGrid posts={searchResults} />
          </Stack>
        )}
      </Container>
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query {
    allContentfulBlogPost {
      edges {
        node {
          ...postFields
        }
      }
    }

    localSearchPosts {
      index
      store
    }
  }
`
