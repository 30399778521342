import React from 'react'
import Img from 'gatsby-image'
import { css } from '@emotion/core'
import { PseudoBox, Box, Tag, Text, useTheme } from '@pw/ui'

const styles = css`
  :hover {
    .content-box__photo {
      img {
        opacity: 1 !important;
        filter: sepia(0);
      }
    }
  }

  .content-box__photo {
    img {
      opacity: 0.7 !important;
      transition: all ease-in-out 300ms !important;
      transform: translateZ(0);
      filter: sepia(0.2);
    }
  }
`

export const ContentBox = ({
  photo,
  tag = false,
  title = false,
  children = null,
  ...props
}) => {
  const theme = useTheme()

  return (
    <PseudoBox
      display="flex"
      alignItems="stretch"
      borderWidth={{ base: 0, md: '1px' }}
      borderColor="darkBeige"
      role="group"
      p="2"
      bg="white"
      rounded="sm"
      boxShadow={theme.shadows.sm}
      transition="all ease-in-out 300ms"
      _hover={{ borderColor: 'brownLightColor', textDecoration: 'none' }}
      css={styles}
      {...props}
    >
      <PseudoBox
        width="100%"
        bg="beige"
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        css={styles}
      >
        <Box position="relative" order={{ base: 2, md: 1 }}>
          {photo && <Img className="content-box__photo" fluid={photo} width="100%" />}

          {tag && (
            <PseudoBox
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              textAlign="center"
            >
              <Box
                display="inline-block"
                position="relative"
                bottom="1"
                borderWidth="1px"
                borderColor="transparentWhite.8"
                p="1px"
              >
                <Tag
                  mx="auto"
                  textTransform="uppercase"
                  fontFamily="heading"
                  rounded="0"
                  color="brownLightColor"
                  bg="beige"
                  size="sm"
                  textAlign="center"
                  transition="all ease-in-out 300ms"
                  _groupHover={{ color: 'brownLightDarkColor', bg: 'beige' }}
                >
                  {tag}
                </Tag>
              </Box>
            </PseudoBox>
          )}
        </Box>

        {(children || title) && (
          <PseudoBox
            flexGrow="1"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="beige"
            px="4"
            py="2"
            order={{ base: 1, md: 2 }}
            fontSize={{ base: '2xl', md: 'lg' }}
          >
            {title ? (
              <PseudoBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
                color="brownLightDarkColor"
                textAlign="center"
                transition="all ease-in-out 300ms"
                _groupHover={{ color: 'brownLightDarkColor' }}
              >
                {/* {!tag && <Text my="2" as="span" width="5%" bg="darkBeige" height="1px" />} */}
                <Text my="2" as="span" width="5%" bg="darkBeige" height="1px" />
                <Text>{title}</Text>
                <Text my="2" as="span" width="5%" bg="darkBeige" height="1px" />
              </PseudoBox>
            ) : (
              children
            )}
          </PseudoBox>
        )}
      </PseudoBox>
    </PseudoBox>
  )
}

export default ContentBox
