import React from 'react'
import { Heading as HeadingUI, Text, Box } from '@chakra-ui/core'

const getFontSizeByType = (as) =>
  ({
    h1: '6xl',
    h2: '3xl',
    h3: '2xl',
    h4: 'xl',
    h5: 'lg',
    h6: 'md',
  }[as] || '3xl')

export const HeadingDefault = (props) => (
  <HeadingUI
    fontSize={getFontSizeByType(props.as)}
    textTransform="uppercase"
    color="green.700"
    {...props}
  />
)

export const HeadingScript = ({
  script,
  direction = 'row',
  reverse = false,
  children,
  ...props
}) => (
  <Heading
    fontWeight="normal"
    display="flex"
    alignItems="center"
    flexDirection={direction}
    {...props}
  >
    <Text
      order={reverse ? 2 : 1}
      as="span"
      fontSize="200%"
      fontFamily="script"
      textTransform="none"
    >
      {script}
    </Text>

    <Text order={reverse ? 1 : 2} pl={direction === 'row' ? 5 : 0} as="span">
      {children}
    </Text>
  </Heading>
)

export const Heading = ({ script = false, ...props }) => {
  if (script) {
    return <HeadingScript script={script} {...props} />
  }

  return <HeadingDefault {...props} />
}

export const SectionHeading = ({
  first,
  second,
  size = 'lg',
  lineColor = 'primaryColor',
  line = false,
  ...props
}) => {
  const sizes = {
    base: {
      lg: '3xl',
      md: '2xl',
      sm: 'xl',
    },
    md: {
      lg: '5xl',
      md: '3xl',
      sm: 'xl',
    },
  }

  return (
    <Heading
      as="h2"
      fontSize={{ base: sizes.base[size], md: sizes.md[size] }}
      py="4"
      mb="0"
      textAlign="center"
      fontWeight="medium"
      color="gray.800"
      {...props}
    >
      {first && (
        <Box fontWeight="normal" fontSize="70%" fontWeight="300" pt="4">
          {first}
        </Box>
      )}
      {second && (
        <Box fontWeight="700" letterSpacing="2px">
          {second}
        </Box>
      )}

      {line && <Box mx="auto" my="4" height="4px" width="100px" bg={lineColor} />}
    </Heading>
  )
}

export default Heading
