import React from 'react'
import { useStaticQuery, graphql, Link as RouterLink } from 'gatsby'
import Img from 'gatsby-image'
import get from '@pw-utils/get'
import { TiSocialPinterest as PinterestIcon } from 'react-icons/ti'
import { FiFacebook as FacebookIcon, FiInstagram as InstagramIcon } from 'react-icons/fi'
import {
  Box,
  Flex,
  Icon,
  Image,
  Heading,
  Text,
  List,
  ListItem,
  Link,
  PseudoBox,
  IconButton,
} from '@pw/ui'

import logo from '../../images/logo_inverted.png'

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allInstaNode(sort: { order: DESC, fields: timestamp }) {
        edges {
          node {
            id
            localFile {
              childImageSharp {
                fixed(width: 300, height: 300) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            thumbnails {
              src
              config_width
              config_height
            }
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  `)

  const instagramPosts = data?.allInstaNode?.edges || []

  const navItems = [
    { label: 'Home', to: '/' },
    { label: 'About', to: '/about' },
    { label: 'Contact', to: '/contact' },
    { label: 'Blog', to: '/blog/page/1' },
  ]

  return (
    <Box {...props}>
      <Box p="8" pb="16" bg="primary.500">
        <Flex
          mx="auto"
          maxWidth="1800px"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Flex flex="2" justifyContent="flex-start">
            <Box>
              <Heading as="h3" mb="4" pt="8" color="primary.100">
                Wellness Travel
              </Heading>

              <List as="ul" fontSize="140%" color="primary.800" spacing="2">
                <ListItem>
                  <FooterLink to="/destinations">Destinations</FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink to="/wellness">Wellness</FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink to="/type-of-trip">Type of Trip</FooterLink>
                </ListItem>
              </List>
            </Box>
          </Flex>

          <Flex
            flex="1"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Image width="100%" maxWidth="150px" src={logo} opacity="0.8" />

            <Flex position="relative" alignItems="center" pt="4">
              <Link
                href="http://pinterest.com/passporttowellness"
                target="_blank"
                color="darkerBeige"
                _hover={{ color: '#827566' }}
              >
                <IconButton
                  icon={PinterestIcon}
                  fontSize="32px"
                  area-label="Pinterest"
                  ml="1"
                  size="32px"
                  width="32px"
                  height="32px"
                  variantColor="primary"
                />
              </Link>

              <Link
                href="http://facebook.com/passtowellness"
                target="_blank"
                color="darkerBeige"
                _hover={{ color: '#827566' }}
              >
                <IconButton
                  icon={FacebookIcon}
                  fontSize="22px"
                  area-label="Facebook"
                  ml="1"
                  size="32px"
                  width="32px"
                  height="32px"
                  variannt="outline"
                  variantColor="primary"
                />
              </Link>

              <Link
                href="http://instagram.com/passporttowellness"
                target="_blank"
                color="darkerBeige"
                _hover={{ color: '#827566' }}
              >
                <IconButton
                  icon={InstagramIcon}
                  fontSize="22px"
                  area-label="Instagram"
                  ml="1"
                  size="32px"
                  width="32px"
                  height="32px"
                  variantColor="primary"
                />
              </Link>
            </Flex>
          </Flex>

          <Flex flex="2" justifyContent="flex-end">
            <Box textAlign="right">
              <Heading as="h3" mb="4" pt="8" color="primary.100">
                Helpful Links
              </Heading>

              <List as="ul" fontSize="140%" color="primary.800" spacing="2">
                <ListItem>
                  <FooterLink to="/resources">Resources</FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink to="/quick-reads">Quick Reads</FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink to="/shop">Shop</FooterLink>
                </ListItem>
              </List>
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box
        overflow="scroll"
        width="100%"
        position="relative"
        height={{ base: '160px', md: '300px ' }}
      >
        <Flex height={{ base: '160px', md: '300px ' }} position="absolute">
          {instagramPosts.map(({ node }, i) => {
            const fixedPhoto = get(node, 'localFile.childImageSharp.fixed', false)

            if (!fixedPhoto) return null

            return (
              <PseudoBox
                role="group"
                bg="primary.100"
                width={{ base: '160px', md: '300px ' }}
                height={{ base: '160px', md: '300px ' }}
                key={`ig:${i}`}
                position="relative"
                cursor="pointer"
                as={Link}
                target="_blank"
                href={`http://instagram.com/p/${node.id}`}
              >
                <PseudoBox
                  opacity="0"
                  transition="all ease-in-out 400ms"
                  top="4%"
                  right="4%"
                  bottom="4%"
                  left="4%"
                  borderWidth="1px"
                  borderColor="transparentWhite.8"
                  _groupHover={{ opacity: 1 }}
                  position="absolute"
                  zIndex="10"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    display="inline-block"
                    borderRadius="25%"
                    p="1"
                    color="green.700"
                    bg="transparentWhite.8"
                  >
                    <Icon as={InstagramIcon} size="32px" color="currentColor" />
                  </Box>
                </PseudoBox>

                <Box display={{ base: 'none', md: 'block' }}>
                  <Img
                    fixed={fixedPhoto}
                    imgStyle={{ display: 'block', width: '300px', height: '300px' }}
                    style={{ display: 'block', width: '300px', height: '300px' }}
                    width="300px"
                    height="300px"
                  />
                </Box>
                <Box display={{ base: 'block', md: 'none' }}>
                  <Img
                    fixed={fixedPhoto}
                    imgStyle={{ display: 'block', width: '160px', height: '160px' }}
                    style={{ display: 'block', width: '160px', height: '160px' }}
                    width="160px"
                    height="160px"
                  />
                </Box>
              </PseudoBox>
            )
          })}
        </Flex>
      </Box>

      <Box p="8">
        <Flex
          mx="auto"
          maxWidth="1800px"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Flex
            flex="1"
            justifyContent={{ base: 'center', md: 'flex-start' }}
            alignItems="center"
            width="100%"
            pb={{ base: '4', md: 0 }}
          >
            <Flex
              as="nav"
              alignItems="center"
              justifyContent={{ bas: 'center', md: 'flex-start' }}
            >
              {navItems.map(({ label, ...linkProps }) => (
                <Link
                  key={label}
                  px="1"
                  py="2"
                  ml="1"
                  _first={{ ml: 0 }}
                  color="brownLightColor"
                  _hover={{ color: 'brownLightDarkColor' }}
                  fontSize="sm"
                  textTransform="uppercase"
                  {...linkProps}
                >
                  {label}
                </Link>
              ))}
            </Flex>
          </Flex>

          <Flex
            flex="2"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            color="gray.500"
          >
            <Box>
              <Text py="1" fontSize="sm">
                Copyright &copy; 2020 Passport to Wellness (Brittney Ayers). All rights
                reserved.
              </Text>

              <Text py="1" fontSize="sm">
                * You may not take any images or content from this site without written
                permission. For more information,{' '}
                <Link to="/contact">contact us here</Link>.
              </Text>
            </Box>
          </Flex>
          <Flex flex="1" justifyContent="flex-end" alignItems="center">
            <Box></Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

const FooterLink = (props) => (
  <Link
    as={RouterLink}
    {...props}
    borderBottomWidth="1px"
    borderBottomColor="primary.800"
    color="primary.800"
    transition="all ease-in-out 300ms"
    _hover={{
      color: 'primary.900',
      borderBottomColor: 'primary.200',
      borderBottomWidth: '2px',
    }}
  />
)

export default Footer
