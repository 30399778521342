import { theme as uiTheme } from '@chakra-ui/core'

// https://smart-swatch.netlify.com/

const primary = [
  {
    50: '#ffe7e5',
    100: '#efc6c3',
    200: '#dfa4a0',
    300: '#d1837b',
    400: '#c36456',
    500: '#a94e3c',
    600: '#843f2e',
    700: '#5f2820',
    800: '#3b1412',
    900: '#1b0101',
  },

  {
    50: '#fff1e7',
    100: '#ebd5c7',
    200: '#d9b7a6',
    300: '#c89984',
    400: '#b77861',
    500: '#9e5c48',
    600: '#7b4537',
    700: '#593426',
    800: '#372116',
    900: '#1a0c01',
  },

  {
    50: '#ffece7',
    100: '#ebcdc8',
    200: '#d9aea6',
    300: '#c88d83',
    400: '#b76d61',
    500: '#9e5448',
    600: '#7c4137',
    700: '#592e26',
    800: '#371b16',
    900: '#1a0503',
  },
]

const green = {
  50: '#ebf6eb',
  100: '#d2ddd7',
  200: '#b9c4bf',
  300: '#a0aba7',
  400: '#86928e',
  500: '#6d7974',
  600: '#535e5a',
  700: '#3a4441',
  800: '#1f2927',
  900: '#001010',
}

const blue = {
  50: '#e8f3fe',
  100: '#ccd8e6',
  200: '#aebdd0',
  300: '#8fa3bc',
  400: '#7089a8',
  500: '#576f8f',
  600: '#435770',
  700: '#2f3e51',
  800: '#1a2533',
  900: '#050c18',
}

const purple = {
  50: '#f0eefa',
  100: '#d3d0e1',
  200: '#b5b1cb',
  300: '#9792b6',
  400: '#7a74a1',
  500: '#605b88',
  600: '#4b466a',
  700: '#35324c',
  800: '#201e2f',
  900: '#0b0914',
}

const orange = {
  50: '#ffefe5',
  100: '#ebd4c6',
  200: '#d8b7a7',
  300: '#c69c85',
  400: '#b58064',
  500: '#9b664a',
  600: '#7a4f39',
  700: '#573827',
  800: '#372116',
  900: '#1a0800',
}

const gray = {
  50: '#f2f2f2',
  100: '#d9d9d9',
  200: '#bfbfbf',
  300: '#a6a6a6',
  400: '#8c8c8c',
  500: '#737373',
  600: '#595959',
  700: '#404040',
  800: '#262626',
  900: '#0d0d0d',
}

// const brown = {
//   50: '#ffefe5',
//   100: '#efd6c3',
//   200: '#e0bb9e',
//   300: '#d39f79',
//   400: '#c78553',
//   500: '#ad6c39',
//   600: '#86532c',
//   700: '#613b1f',
//   800: '#3b2410',
//   900: '#180a00',
// }

const brown = {
  50: '#f8f1eb',
  100: '#dfd9d3',
  200: '#c7c0b9',
  300: '#b1a79c',
  400: '#9b8f80',
  500: '#827566',
  600: '#655b4f',
  700: '#484138',
  800: '#2c2720',
  900: '#130c04',
}

const brownLighterColor = brown[100]
const brownLightColor = brown[200]
const brownLightColorHover = brown[400]
const brownLightDarkColor = brown[600]

const brownLight = {
  50: brown[200],
  100: brown[200],
  200: brown[200],
  300: brown[200],
  400: brown[200],
  500: brown[200],
  600: brownLightColorHover,
  700: brownLightColorHover,
  800: brownLightColorHover,
  900: brownLightColorHover,
}

const breakpoints = ['30em', '50em', '100em', '120em']
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const colors = {
  ...uiTheme.colors,
  grayBlue: uiTheme.colors.gray,
  gray,
  green,
  greenColor: green[300],
  greenHoverColor: green[500],
  blue,
  purple,
  orange,
  brown,
  brownLight,
  brownLightColor,
  brownLightColorHover,
  brownLightDarkColor,
  brownLighterColor,
  beige: 'rgb(251	249	246)',
  darkBeige: '#E6E2DD',
  darkerBeige: '#827566',
  darkestBeige: '#655b4f',
  primaryColor: green[500],
  primary: green,
  secondaryColor: '#6996b0',
  secondary: {
    50: '#e4f5fe',
    100: '#c8dde8',
    200: '#aac4d5',
    300: '#89adc2',
    400: '#6996b0',
    500: '#507c97',
    600: '#3d6176',
    700: '#294555',
    800: '#152936',
    900: '#001018',
  },
  pink: primary[2],
  transparentWhite: {
    1: 'rgba(255, 255, 255, 0.1)',
    2: 'rgba(255, 255, 255, 0.2)',
    3: 'rgba(255, 255, 255, 0.3)',
    4: 'rgba(255, 255, 255, 0.4)',
    5: 'rgba(255, 255, 255, 0.5)',
    6: 'rgba(255, 255, 255, 0.6)',
    7: 'rgba(255, 255, 255, 0.7)',
    8: 'rgba(255, 255, 255, 0.8)',
    9: 'rgba(255, 255, 255, 0.9)',
  },
  transparentBeige: {
    '0.5': 'rgb(101, 91, 79, 0.05)',
    1: 'rgb(101, 91, 79, 0.1)',
    2: 'rgb(101, 91, 79, 0.2)',
    3: 'rgb(101, 91, 79, 0.3)',
    4: 'rgb(101, 91, 79, 0.4)',
    5: 'rgb(101, 91, 79, 0.5)',
    6: 'rgb(101, 91, 79, 0.6)',
    7: 'rgb(101, 91, 79, 0.7)',
    8: 'rgb(101, 91, 79, 0.8)',
    9: 'rgb(101, 91, 79, 0.9)',
  },
}

const sizes = {
  ...uiTheme.sizes,
}

const theme = {
  ...uiTheme,
  breakpoints,
  shadows: {
    sm: `0px 0px 2px 2px ${colors.transparentBeige['0.5']}`,
  },
  fonts: {
    ...uiTheme.fonts,
    heading: 'Montserrat, Source Sans Pro, Helvetica, Arial, sans-serif',
    body: 'Montserrat, Source Sans Pro, Open Sans, Helvetica, Arial, sans-serif',
    script: 'kitchen_boldregular',
  },
  fontSizes: {
    ...uiTheme.fontSizes,
    xs: '14px',
    sm: '16px',
    md: '18px',
    lg: '20px',
    xl: '24px',
    '2xl': '28px',
    '3xl': '36px',
    '4xl': '42px',
    '4-5xl': '46px',
    '5xl': '54px',
    '5-6xl': '58px',
    '6xl': '64px',
  },
  colors,
  sizes,
}

export default theme
