import React from 'react'
import { Text, Box, Heading, Button } from '@pw/ui'

import Section from '../../components/Layout/Section'

const WelcomeTo = (props) => (
  <Section py={{ base: 0, md: 20 }} pt={{ base: 1, md: 10 }} {...props}>
    <Heading
      as="h2"
      fontSize={{ base: '3xl', md: '5xl' }}
      py="4"
      mb="0"
      textAlign="center"
      fontWeight="medium"
      color="gray.800"
    >
      <Box fontWeight="normal" fontSize="70%" fontWeight="300" pt="4">
        Welcome to
      </Box>
      <Box fontWeight="700" letterSpacing="2px">
        Passport to Wellness
      </Box>

      <Box mx="auto" my="4" height="4px" width="100px" bg="primaryColor" />
    </Heading>

    <Text
      p="0"
      px={{ base: 10, md: 0 }}
      maxWidth="700px"
      margin="0 auto"
      textAlign="center"
      color="gray.800"
      fontSize="xl"
    >
      A new website for wellness travel around the world. Find a cure for the common
      vacation with practical wellness travel tips, guides, and inspiration.
    </Text>

    <Box display={{ base: 'flex', md: 'none' }} width="100%" pb="6">
      <Button
        displayType="primary"
        mt="5"
        mx="auto"
        fontWeight="medium"
        to="/wellness-travel"
        textTransform="uppercase"
        fontWeight="600"
      >
        Learn About Wellness Travel
      </Button>
    </Box>
  </Section>
)

export default WelcomeTo
