import React from 'react'
import { Box } from '@pw/ui'

import SEO from '../SEO'

import TopBar from './TopBar'
import Header from './Header'
import Footer from './Footer'
import Container from './Container'
import NewsletterForm from './NewsletterForm'

// import logoInverted from '../../images/logo_inverted.png'

import { defaultLeftNav, defaultRightNav } from './data'

export const Layout = ({
  children,
  header = 'short',
  headerTitle = null,
  location,
  meta = {},
  seo = {},
  ...props
}) => {
  return (
    <Box {...props}>
      <SEO seo={{ url: location.href, ...seo }} meta={meta} />

      <TopBar display={{ base: 'none', md: 'inherit' }} zIndex="15" />

      <Header
        type={header}
        zIndex="9"
        position="relative"
        leftNav={defaultLeftNav}
        rightNav={defaultRightNav}
      />

      <Box zIndex="5" position="relative">
        {children}
      </Box>

      <Footer />
    </Box>
  )
}

export { Container, NewsletterForm }
