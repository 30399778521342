import React from 'react'
import { SectionHeading } from '@pw/ui'

import Section from '../../components/Layout/Section'
import RowOfPosts from '../../components/Posts/Row'

const LearnAboutWellnessTravel = ({ posts, ...props }) => (
  <Section
    bg={{ base: 'white', md: 'beige' }}
    py={{ base: 5, md: 10 }}
    px={{ base: 5, md: 0 }}
    childProps={{ maxWidth: '900px' }}
    {...props}
  >
    <SectionHeading first="Learn About" second="Wellness Travel" size="lg" />

    <RowOfPosts hideCategory posts={posts} mb="10" />
  </Section>
)

export default LearnAboutWellnessTravel
