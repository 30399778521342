import chunk from 'lodash/chunk'

export const baseCategories = [
  { title: 'Wellness', to: '/wellness' },
  { title: 'Type Of Trip', to: '/type-of-trip' },
  { title: 'Resources', to: '/resources' },
  { title: 'Quick Reads', to: '/quick-reads' },
]

export const categories = [
  { title: 'Wellness Travel', to: '/wellness-travel' },
  { title: 'Daily Wellness', to: '/daily-wellness' },
  { title: 'Resorts', to: '/resorts' },
  { title: 'Retreats', to: '/retreats' },
  { title: 'Camping', to: '/camping' },
  { title: 'Tips', to: '/tips' },
  { title: 'Guides', to: '/guides' },
  { title: 'Gear', to: '/gear' },
  { title: 'Packing', to: '/packing' },
]

export const siteMapLinks = chunk(
  [
    { title: 'Home', to: '/' },
    { title: 'About', to: '/about' },
    { title: 'Contact', to: '/contact' },
    { title: 'Blog', to: '/blog' },
    // { title: 'Press', to: '/press' },
    // { title: 'FAQ', to: '/faq' },
    // { title: 'Sitemap', to: '/sitemap' },
    ...baseCategories,
    ...categories,
  ],
  7,
)

export const defaultLeftNav = [
  {
    to: '/destinations',
    label: 'Destinations',
    sub: [
      { to: '/united-states', label: 'United States' },
      {
        to: '/indonesia',
        label: 'Indonesia',
      },
      {
        to: '/cambodia',
        label: 'Cambodia',
      },
    ],
  },
  {
    to: '/wellness',
    label: 'Wellness',
    sub: [
      { to: '/daily-wellness', label: 'Daily Wellness' },
      { to: '/wellness-travel', label: 'Wellness Travel' },
    ],
  },
  {
    to: '/type-of-trip',
    label: 'Type of Trip',
    sub: [
      { to: '/retreats', label: 'Retreats' },
      { to: '/resorts', label: 'Resorts' },
      { to: '/camping', label: 'Camping' },
    ],
  },
]

export const defaultRightNav = [
  {
    to: '/resources',
    label: 'Resources',
    sub: [
      { to: '/tips', label: 'Tips' },
      { to: '/guides', label: 'Guides' },
      { to: '/gear', label: 'Gear' },
      { to: '/packing', label: 'Packing' },
    ],
  },
  { to: '/quick-reads', label: 'Quick Reads' },
  { to: '/shop', label: 'Shop' },
]
