import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Flex, Input, Text, Button } from '@pw/ui'

export const NewsletterForm = (props) => {
  const emailRef = React.useRef(null)
  const [emailIsLoading, setEmailIsLoading] = React.useState(false)
  const [emailNotification, setEmailNotification] = React.useState(false)
  const [emailHasError, setEmailHasError] = React.useState(false)

  const onSubmitNewsletter = (e) => {
    e.preventDefault()
    const email = emailRef.current.value

    setEmailNotification(false)
    setEmailHasError(false)
    setEmailIsLoading(true)

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      addToMailchimp(email).then((data) => {
        setEmailIsLoading(false)

        if (data.result === 'success') {
          emailRef.current.value = ''

          setEmailHasError(false)
          setEmailNotification('You have successfully been added to the newsletter')
        } else {
          emailRef.current.value = ''

          setEmailHasError(true)
          setEmailNotification(
            data.msg ? (
              <span dangerouslySetInnerHTML={{ __html: data.msg }} />
            ) : (
              'There was an unkown error'
            ),
          )
        }
      })
    } else {
      setEmailHasError(true)
      setEmailIsLoading(false)
      setEmailNotification(`Please enter a valid email address: ${email}`)
    }
  }

  return (
    <Flex
      as="form"
      flexDirection={{ base: 'column', md: 'row' }}
      {...props}
      onSubmit={onSubmitNewsletter}
    >
      <Input ref={emailRef} type="email" placeholder="your@email.com" />

      <Button
        displayType="secondary"
        type="submit"
        isLoading={emailIsLoading}
        ml={{ base: '0', md: '2' }}
        mt={{ base: 2, md: props.flexDirection === 'column' ? '2' : '0' }}
      >
        Sign Up
      </Button>

      {emailNotification && (
        <Text color={emailHasError ? 'red' : 'gray'}>{emailNotification}</Text>
      )}
    </Flex>
  )
}

export default NewsletterForm
