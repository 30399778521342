// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-destinations-js": () => import("./../../../src/pages/destinations.js" /* webpackChunkName: "component---src-pages-destinations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-destination-js": () => import("./../../../src/templates/destination.js" /* webpackChunkName: "component---src-templates-destination-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-category-base-page-js": () => import("./../../../src/templates/postCategoryBasePage.js" /* webpackChunkName: "component---src-templates-post-category-base-page-js" */),
  "component---src-templates-post-category-page-js": () => import("./../../../src/templates/postCategoryPage.js" /* webpackChunkName: "component---src-templates-post-category-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-page-js": () => import("./../../../src/templates/postsPage.js" /* webpackChunkName: "component---src-templates-posts-page-js" */),
  "component---src-templates-product-category-js": () => import("./../../../src/templates/productCategory.js" /* webpackChunkName: "component---src-templates-product-category-js" */)
}

