import React from 'react'
import { graphql } from 'gatsby'
import find from 'lodash/find'
import { Flex, SectionHeading, Button } from '@pw/ui'

import { Layout } from '../components/Layout'
import AboutMeBlock from '../components/Blocks/AboutMe'
import WelcomeToBlock from '../components/Blocks/WelcomeTo'
import NewsletterBlock from '../components/Blocks/Newsletter'
import SearchBlock from '../components/Blocks/Search'
import LearnAboutWellnesTravelBlock from '../components/Blocks/LearnAboutWellnessTravel'
import FromTheBlogBlock from '../components/Blocks/FromTheBlog'

import suitcasesPhoto from '../images/suitcases.jpg'

const hideOnMobileProps = (display = 'block') => ({
  display: { base: 'none', md: display },
})

export const IndexPage = ({ data, location }) => {
  const travelPosts = data?.travelPosts?.edges || []
  const eatPosts = data?.eatPosts?.edges || []
  const sweatPosts = data?.sweatPosts?.edges || []
  const relaxPosts = data?.relaxPosts?.edges || []
  const sleepPosts = data?.sleepPosts?.edges || []
  const verticals = data?.allContentfulVerticals?.edges || []
  const wellnessTravelVertical = find(
    verticals,
    ({ node }) => node.slug === 'wellness-travel',
    {},
  )
  const otherVertical = {
    node: wellnessTravelVertical?.node?.stickyPost || travelPosts?.slice(0, 1)?.node,
  }

  const topPicksToday = [
    // ...travelPosts.slice(0, 1),
    ...eatPosts.slice(0, 1),
    ...sweatPosts.slice(0, 1),
    ...relaxPosts.slice(0, 1),
    ...sleepPosts.slice(0, 1),
  ]
    .filter(Boolean)
    .slice(0, 3)

  const learnAboutTravelPosts = [
    {
      node: {
        ...wellnessTravelVertical.node,
        title: `What is ${wellnessTravelVertical.node.title}?`,
        customPhoto: suitcasesPhoto,
      },
    },
    otherVertical,
    ...travelPosts.slice(0, 0),
  ]

  return (
    <Layout
      header="full"
      location={location}
      seo={{ postTitle: 'A Wellness Travel Blog', title: 'Passport to Wellness' }}
    >
      <Flex direction="column">
        <WelcomeToBlock />

        <FromTheBlogBlock posts={topPicksToday} />

        <NewsletterBlock {...hideOnMobileProps()} />

        <LearnAboutWellnesTravelBlock
          posts={learnAboutTravelPosts}
          {...hideOnMobileProps()}
        />

        <AboutMeBlock {...hideOnMobileProps('flex')} />
        <SearchBlock mt="0" />
      </Flex>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allContentfulBlogPost(limit: 5, sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
          ...postFields
        }
      }
    }

    allContentfulVerticals {
      edges {
        node {
          id
          slug
          title: name
          heroImage: mainPhoto {
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid
            }
          }
          stickyPost {
            ...postFields
          }
        }
      }
    }

    travelPosts: allContentfulBlogPost(
      limit: 5
      filter: { category: { slug: { eq: "travel" } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }

    sleepPosts: allContentfulBlogPost(
      limit: 5
      filter: { category: { slug: { eq: "sleep" } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }

    eatPosts: allContentfulBlogPost(
      limit: 5
      filter: { category: { slug: { eq: "eat" } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }

    sweatPosts: allContentfulBlogPost(
      limit: 5
      filter: { category: { slug: { eq: "sweat" } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }

    relaxPosts: allContentfulBlogPost(
      limit: 5
      filter: { category: { slug: { eq: "relax" } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }
  }
`
