import React from 'react'
import { Flex, SectionHeading, Button } from '@pw/ui'

import Section from '../../components/Layout/Section'
import PostsGrid from '../../components/Posts/Grid'

const FromTheBlog = ({ posts, props }) => (
  <Section py="16" pt="8" {...props}>
    <SectionHeading first="Featured" second="From The Blog" size="md" />

    <PostsGrid hideCategory={false} rowSize="3" posts={posts} />

    <Flex alignItems="center" justifyContent="center" p="10" width="100%">
      <Button displayType="primary" to="/blog/page/1">
        View More Posts
      </Button>
    </Flex>
  </Section>
)

export default FromTheBlog
